try {
  localStorage.setItem('test', 'test');
  localStorage.removeItem('test');
} catch (_e) {
  let noop = function () {
    return null;
  };

  let fallback = {
    getItem: noop,
    setItem: noop,
    removeItem: noop,
    clear: noop,
    set: noop,
    get: noop,
  };

  ['localStorage', 'sessionStorage'].forEach((name) => {
    Object.defineProperty(window, name, {
      value: fallback,
      writable: false,
    });
  });
}
