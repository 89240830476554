/**
 * !!IMPORTANT!!
 *
 * This module imports the polyfills that will be included in the main bundle
 *
 * These polyfills are required by the browsers that supports "nomodule"
 *
 * https://caniuse.com/?search=nomodule
 *
 */

//this makes the localStorage access safe when the feature is disabled
import './lib/localStorage';
// Edge 18 supports nomodule but does not supports customElements :(
import './lib/customElements';

if (!window.queueMicrotask) {
  //required by Safari 11, meh
  window.queueMicrotask = require('queue-microtask');
}

if (process.env.NODE_ENV !== 'production') {
  // We need this to make ReactDevOverlay on IE11 - Edge 18
  require('@webcomponents/shadydom');
}

/**
 * !!IMPORTANT!!
 *
 * This module imports the polyfills that will be included in the nomodule bundle
 *
 * These polyfills are NOT required by the browsers that supports "nomodule"
 *
 * https://caniuse.com/?search=nomodule
 */

import './lib/classList';
import './lib/closest';
import './lib/firstElementChild';
import './lib/matches';
import './lib/nodeListForEach';
import './lib/remove';
import './lib/svgUse';

if (typeof window.HTMLTemplateElement === 'undefined') {
  require('@webcomponents/template');
}
