if (!window.customElements || !window.customElements.whenDefined) {
  if (window.customElements) customElements.forcePolyfill = true;

  // Only works in Chrome and Firefox, does not work in IE:
  Object.setPrototypeOf =
    Object.setPrototypeOf ||
    function (obj, proto) {
      obj.__proto__ = proto;

      return obj;
    };

  require('@webcomponents/custom-elements');
}

if (process.env.NODE_ENV === 'production') {
  const prevDefine = customElements.define;

  Object.defineProperty(customElements, 'define', {
    value(name, constuctor, options) {
      try {
        return prevDefine.call(customElements, name, constuctor, options);
      } catch (o_O) {
        // eslint-disable-next-line no-console
        console.error(o_O);
      }
    },
  });
}
