const loadSvgUse = (() => {
  /**
   * Since hasFeatures is deprecated, all modern browsers will return always true
   *
   * @link https://developer.mozilla.org/en-US/docs/Web/API/DOMImplementation/hasFeature
   * @link https://www.w3.org/TR/SVG11/feature.html
   */
  try {
    return !document.implementation.hasFeature(
      'http://www.w3.org/TR/SVG11/feature#ExternalResourcesRequired',
      '1.1'
    );
  } catch (e) {}

  return true;
})();

if (loadSvgUse) {
  require('@pepita/svg-use-polyfill');
}
