// Overwrites native 'firstElementChild' prototype.
// Adds DocumentFragment support for IE11 https://developer.mozilla.org/en-US/docs/Web/API/DocumentFragment/firstElementChild
// Returns array instead of HTMLCollection.
function defineFirstElementChild(prototype) {
  if (!('firstElementChild' in prototype)) {
    Object.defineProperty(prototype, 'firstElementChild', {
      get() {
        var node,
          nodes = this.childNodes,
          i = 0;

        while ((node = nodes[i++])) {
          if (node.nodeType === 1) {
            return node;
          }
        }

        return null;
      },
    });
  }
}

if (
  [Document, DocumentFragment].some(
    // eslint-disable-next-line
    (el) => !el.prototype.hasOwnProperty('firstElementChild')
  )
) {
  [window.Element, window.Document, window.DocumentFragment]
    .map((c) => c.prototype)
    .forEach(defineFirstElementChild);
}
