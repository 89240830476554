if (!Element.prototype.remove) {
  // from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
  [Element.prototype, CharacterData.prototype, DocumentType.prototype].forEach(
    function (item) {
      // eslint-disable-next-line
      if (item.hasOwnProperty('remove')) {
        return;
      }

      Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          if (this.parentNode !== null) this.parentNode.removeChild(this);
        },
      });
    }
  );
}
